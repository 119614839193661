@font-face { font-family: 'Nunito';
  src: url('/fonts/Nunito-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Nunito';
  src: url('/fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: bold; }

body, input, textarea {
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: inherit;
}

@media (max-width: 768px) {
  .slick-arrow {
    display: none !important;
  }
}

.slick-dots li button:before {
  color: white !important;
}

* {
  min-width: 0;
  min-height: 0;
}
